import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { circleBlock, icon as iconStyle } from "./circle-block.module.scss"

const CircleBlock = ({
  buttonText = "Learn More",
  children,
  icon = null,
  iconImg,
  target = "/",
}) => (
  <div data-testid="circle-block" className={circleBlock}>
    {iconImg ? (
      <div className={iconStyle}>
        <Img fluid={iconImg.childImageSharp.fluid} />
      </div>
    ) : (
      <div className={iconStyle}>{icon}</div>
    )}
    <div>
      {children}
      <Link to={target}>{buttonText}</Link>
    </div>
  </div>
)

export default CircleBlock
