import React from "react"
import Img from "gatsby-image"

import ImagePlaceholder from "../image-placeholder/image-placeholder"
import ButtonItem from "../button-item/button-item"

import {
  sectionBlock,
  sectionBlockInner,
  sectionBlockContent,
} from "./content-section.module.scss"

const ContentSection = ({
  heading,
  subheading,
  children,
  buttonText = "Get Started",
  target = "/",
  imgSrc,
  ...props
}) => {
  return (
    <div
      className={sectionBlock}
      id={props.id}
      data-testid={props.testid ? props.testid : ""}
    >
      <div className={sectionBlockInner}>
        <figure>
          {imgSrc ? (
            <Img fluid={imgSrc.childImageSharp.fluid} />
          ) : (
            <ImagePlaceholder />
          )}
        </figure>
        <div className={sectionBlockContent}>
          {subheading && <h3>{subheading}</h3>}
          <h2>{heading}</h2>
          {children}
          <ButtonItem target={target} isGhost={true} isPill={true}>
            {buttonText}
          </ButtonItem>
        </div>
      </div>
    </div>
  )
}

export default ContentSection
