import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeroUnit from "../components/hero-unit/hero-unit"
import CircleBlock from "../components/circle-block/circle-block"
import ContentSection from "../components/content-section/content-section"
import {
  overviewSection,
  overviewItem,
} from "../components/overview-section/overview-section.module.scss"

import "./services.scss"

const getImages = graphql`
  query {
    serviceImage: file(relativePath: { eq: "branding.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    designImage: file(relativePath: { eq: "web-design-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ServicePage = () => {
  const { designImage, serviceImage } = useStaticQuery(getImages)

  return (
    <LayoutMain className="page-services">
      <SeoHeaders title="Services" keywords={[`services`]} />
      <HeroUnit
        class="with-overview"
        heroImage={serviceImage}
        pageTitle="Services"
      >
        <p>We are a full service digital agency.</p>
      </HeroUnit>
      <section className="section-overview">
        <div data-testid="overview-section" className={overviewSection}>
          <div className={overviewItem}>
            <CircleBlock iconImg={designImage} target="/web-presence">
              <h3>Web Presence</h3>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Exercitationem non incidunt porro commodi modi ipsa quaerat
                explicabo repellendus asperiores.
              </p>
            </CircleBlock>
          </div>
          <div className={overviewItem}>
            <CircleBlock iconImg={designImage} target="/local-marketing">
              <h3>Local Marketing</h3>
              <p>
                Local marketing is a successful marketing strategy that targets
                customers/clients within a certain geographic area
              </p>
            </CircleBlock>
          </div>
          <div className={overviewItem}>
            <CircleBlock iconImg={designImage} target="/lead-generation">
              <h3>Lead Generation</h3>
              <p>
                Lead generation has long been a key part of the online sales
                funnel for most B2B businesses as their sales pages may cost
                thousands of dollars to produce
              </p>
            </CircleBlock>
          </div>
        </div>
      </section>
      <section className="section-services">
        <ContentSection
          heading="Branding"
          buttonText="Learn More"
          target="/service/branding"
          testid="section-branding"
        >
          <p>We design a unique identity for your business</p>
        </ContentSection>
        <ContentSection
          heading="Website Audits"
          target="/service/website-audits"
          buttonText="Learn More"
          testid="section-website-audits"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Web Design"
          buttonText="Learn More"
          target="/service/web-design"
          testid="section-web-design"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="E-Commerce"
          buttonText="Learn More"
          target="/service/e-commerce"
          testid="section-e-commerce"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="App Design"
          buttonText="Learn More"
          target="/service/app-design"
          testid="section-app-design"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Blog Development"
          buttonText="Learn More"
          target="/service/blog-development"
          testid="section-blog-development"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Website Optimization"
          buttonText="Learn More"
          target="/service/website-optimization"
          testid="section-website-optimization"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Website Copywriting"
          buttonText="Learn More"
          target="/service/website-copywriting"
          testid="section-website-copywriting"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Website Maintenance"
          buttonText="Learn More"
          target="/service/website-maintenance"
          testid="section-website-maintenance"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Web Citations"
          buttonText="Learn More"
          target="/service/web-citations"
          testid="section-web-citations"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Social Media"
          buttonText="Learn More"
          target="/service/social-media"
          testid="section-social-media"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Press Releases"
          buttonText="Learn More"
          target="/service/press-releases"
          testid="section-press-releases"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Facebook Marketing"
          buttonText="Learn More"
          target="/service/facebook-marketing"
          testid="section-facebook-marketing"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="PPC Marketing"
          buttonText="Learn More"
          target="/service/ppc-marketing"
          testid="section-ppc-marketing"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="List Building"
          buttonText="Learn More"
          target="/service/list-building"
          testid="section-list-building"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="E-Mail Marketing"
          buttonText="Learn More"
          target="/service/email-marketing"
          testid="section-email-marketing"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Landing Pages"
          buttonText="Learn More"
          target="/service/landing-pages"
          testid="section-landing-pages"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Funnel Development"
          buttonText="Learn More"
          target="/service/funnel-development"
          testid="section-funnel-development"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Content Marketing"
          buttonText="Learn More"
          target="/service/content-marketing"
          testid="section-content-marketing"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="Video Marketing"
          buttonText="Learn More"
          target="/service/video-marketing"
          testid="section-video-marketing"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
        <ContentSection
          heading="E-Book Creation"
          buttonText="Learn More"
          target="/service/e-book-creation"
          testid="section-e-book-creation"
        >
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem non incidunt porro commodi modi ipsa quaerat
            explicabo repellendus asperiores quo.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati
            quasi et natus rerum aliquid nam quam incidunt iste consequatur
            temporibus.
          </p>
        </ContentSection>
      </section>
    </LayoutMain>
  )
}

export default ServicePage
